.App {
  text-align: center;
  padding-bottom: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sigCanvas {
  border: 1px black solid;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 40%; /* Full width */
  height: 30%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

body {
  top: 0px !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.skiptranslate > span {
  display: none !important;
}

#goog-gt-tt {
  display: none !important;
}

/* class assigned by google translate seems randomly generated, but kept getting the same one.
Prevents adding bg/box shadow to text where tooltip comes from */
.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  background-color: transparent !important;
  box-shadow: none !important;
}

div#google_translate_element {
  /*min-width: 50% !important;*/
  /* make the select box larger
   */
}

.skiptranslate > iframe {
  display: none !important;
}

select.goog-te-combo {
  /* make the select box larger
   */
  height: 100% !important;
  width: 100% !important;
  padding: 3px 3px 3px 3px !important;
  font-size: 1em !important;
}

/* 2nd child is the iframe */

.photoPreview {
  width: 40%;
  height: 40%;
  object-fit: contain;
  margin: 2%;
}

.field-frame-cta {
  padding-top: 0 !important;
}

.field-frame-cta-grid {
  position: fixed;
  bottom: 0;
  padding-top: 0 !important;
  background-color: white;
  z-index: 10;
}

@media (min-width: 300px) {
  .field-frame-cta {
    padding-top: 20px !important;
  }

  .field-frame-cta-grid {
    position: relative;
    padding-top: 20px !important;
    background-color: transparent;
    z-index: unset;
  }
}

@media (min-width: 640px) {
  .field-frame-cta {
    padding-top: 20px !important;
  }

  .field-frame-cta-grid {
    position: relative;
    padding-top: 20px !important;
    background-color: transparent;
    z-index: unset;
  }
}
